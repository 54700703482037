import { wrap } from ".";
import { Entry, IEntry, ILink, isEntry } from "../base";
import { ISectionBlockText, SectionBlockText } from "./section-block-text";
import { ISectionEmailCapture, SectionEmailCapture } from "./section-email-capture";

export interface ISectionCodeWidgetFields {
  internalTitle: string;
  view: SectionCodeWidgetView;
  parameters?: any;
  bookmarkTitle?: string;
  sections?: Array<ILink<'Entry'> | SectionCodeWidgetSection>;
}

export type SectionCodeWidgetView = 'podcast-index' | 'blogs-feed' | 'podcast-card' | 'pricing' | 'media-search' | 'media-gallery' | 'password-redirect' | 'spacer' | 'object-search' | 'resource-list' | 'resource-search';
export type SectionCodeWidgetSection = ISectionBlockText | ISectionEmailCapture;
export type SectionCodeWidgetSectionClass = SectionBlockText | SectionEmailCapture;

/**
 * Section: Code Widget
 * Render code defined sections  from JSON parameters.
 */
export interface ISectionCodeWidget extends IEntry<ISectionCodeWidgetFields> {
}

export function isSectionCodeWidget(entry: IEntry<any>): entry is ISectionCodeWidget {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'section-code-widget'
}

export class SectionCodeWidget extends Entry<ISectionCodeWidgetFields> implements ISectionCodeWidget {
  get internalTitle(): string {
    return this.fields.internalTitle
  }

  get internal_title(): string {
    return this.fields.internalTitle
  }

  get view(): SectionCodeWidgetView {
    return this.fields.view
  }

  get parameters(): any | undefined {
    return this.fields.parameters
  }

  get bookmarkTitle(): string | undefined {
    return this.fields.bookmarkTitle
  }

  get bookmark_title(): string | undefined {
    return this.fields.bookmarkTitle
  }

  get sections(): Array<SectionCodeWidgetSectionClass | null> | undefined {
    return !this.fields.sections ? undefined :
      this.fields.sections.map((item) =>
        isEntry(item) ? wrap<'section-block-text' | 'section-email-capture'>(item) : null
      )
  }

  constructor(entry: ISectionCodeWidget);
  constructor(id: string, fields: ISectionCodeWidgetFields);
  constructor(entryOrId: ISectionCodeWidget | string, fields?: ISectionCodeWidgetFields) {
    super(entryOrId, 'section-code-widget', fields)
  }
}
