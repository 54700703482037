import * as C from ".";
import { IEntry } from "../base";

export * from "./cart_button";
export * from "./card";
export * from "./call_to_action";
export * from "./email";
export * from "./conference";
export * from "./login_button";
export * from "./faq";
export * from "./form_field";
export * from "./menu";
export * from "./menu_button";
export * from "./migration_history";
export * from "./page_metadata";
export * from "./ministry";
export * from "./page";
export * from "./partner_church";
export * from "./resource";
export * from "./redirect";
export * from "./resource_set";
export * from "./section-block-text";
export * from "./resource_tree";
export * from "./section-card-deck";
export * from "./section-card";
export * from "./section-domain-object-header";
export * from "./section-code-widget";
export * from "./section-contact-us";
export * from "./section-faq";
export * from "./section-email-capture";
export * from "./section-hero";
export * from "./section-featured-items";
export * from "./section-http-error";
export * from "./section-image-gallery";
export * from "./section-intro";
export * from "./section-marquee-text";
export * from "./section-ministry-details";
export * from "./section-location-map";
export * from "./section-partner-churches";
export * from "./section-product-list";
export * from "./section-resource-landing";
export * from "./section-video";
export * from "./section-testimonials";
export * from "./site-config";
export * from "./section-video-highlight";
export * from "./testimonial";

export interface TypeDirectory {
  'cartButton': C.ICartButton;
  'card': C.ICard;
  'callToAction': C.ICalltoAction;
  'email': C.IEmail;
  'conference': C.IConference;
  'loginButton': C.ILoginButton;
  'faq': C.IFaq;
  'formField': C.IFormField;
  'menu': C.IMenu;
  'menuButton': C.IMenuButton;
  'migrationHistory': C.IMigrationHistory;
  'pageMetadata': C.IPageMetadata;
  'ministry': C.IMinistry;
  'page': C.IPage;
  'partnerChurch': C.IPartnerChurch;
  'resource': C.IResource;
  'redirect': C.IRedirect;
  'resourceSet': C.IResourceSet;
  'section-block-text': C.ISectionBlockText;
  'resourceTree': C.IResourceTree;
  'section-card-deck': C.ISectionCardDeck;
  'section-card': C.ISectionCard;
  'section-domain-object-header': C.ISectionDomainObjectHeader;
  'section-code-widget': C.ISectionCodeWidget;
  'section-contact-us': C.ISectionContactUs;
  'section-faq': C.ISectionFaq;
  'section-email-capture': C.ISectionEmailCapture;
  'section-hero': C.ISectionHero;
  'section-featured-items': C.ISectionFeaturedItems;
  'section-http-error': C.ISectionHttpError;
  'section-image-gallery': C.ISectionImageGallery;
  'section-intro': C.ISectionIntro;
  'section-marquee-text': C.ISectionMarqueeText;
  'section-ministry-details': C.ISectionMinistryDetails;
  'section-location-map': C.ISectionLocationMap;
  'section-partner-churches': C.ISectionPartnerChurches;
  'section-product-list': C.ISectionProductList;
  'section-resource-landing': C.ISectionResourceLanding;
  'section-video': C.ISectionVideo;
  'section-testimonials': C.ISectionTestimonials;
  'site-config': C.ISiteConfig;
  'section-video-highlight': C.ISectionVideoHighlight;
  'testimonial': C.ITestimonial;
}

export interface ClassDirectory {
  'cartButton': C.CartButton;
  'card': C.Card;
  'callToAction': C.CalltoAction;
  'email': C.Email;
  'conference': C.Conference;
  'loginButton': C.LoginButton;
  'faq': C.Faq;
  'formField': C.FormField;
  'menu': C.Menu;
  'menuButton': C.MenuButton;
  'migrationHistory': C.MigrationHistory;
  'pageMetadata': C.PageMetadata;
  'ministry': C.Ministry;
  'page': C.Page;
  'partnerChurch': C.PartnerChurch;
  'resource': C.Resource;
  'redirect': C.Redirect;
  'resourceSet': C.ResourceSet;
  'section-block-text': C.SectionBlockText;
  'resourceTree': C.ResourceTree;
  'section-card-deck': C.SectionCardDeck;
  'section-card': C.SectionCard;
  'section-domain-object-header': C.SectionDomainObjectHeader;
  'section-code-widget': C.SectionCodeWidget;
  'section-contact-us': C.SectionContactUs;
  'section-faq': C.SectionFaq;
  'section-email-capture': C.SectionEmailCapture;
  'section-hero': C.SectionHero;
  'section-featured-items': C.SectionFeaturedItems;
  'section-http-error': C.SectionHttpError;
  'section-image-gallery': C.SectionImageGallery;
  'section-intro': C.SectionIntro;
  'section-marquee-text': C.SectionMarqueeText;
  'section-ministry-details': C.SectionMinistryDetails;
  'section-location-map': C.SectionLocationMap;
  'section-partner-churches': C.SectionPartnerChurches;
  'section-product-list': C.SectionProductList;
  'section-resource-landing': C.SectionResourceLanding;
  'section-video': C.SectionVideo;
  'section-testimonials': C.SectionTestimonials;
  'site-config': C.SiteConfig;
  'section-video-highlight': C.SectionVideoHighlight;
  'testimonial': C.Testimonial;
}

export function wrap(entry: C.ICartButton): C.CartButton;
export function wrap(entry: C.ICard): C.Card;
export function wrap(entry: C.ICalltoAction): C.CalltoAction;
export function wrap(entry: C.IEmail): C.Email;
export function wrap(entry: C.IConference): C.Conference;
export function wrap(entry: C.ILoginButton): C.LoginButton;
export function wrap(entry: C.IFaq): C.Faq;
export function wrap(entry: C.IFormField): C.FormField;
export function wrap(entry: C.IMenu): C.Menu;
export function wrap(entry: C.IMenuButton): C.MenuButton;
export function wrap(entry: C.IMigrationHistory): C.MigrationHistory;
export function wrap(entry: C.IPageMetadata): C.PageMetadata;
export function wrap(entry: C.IMinistry): C.Ministry;
export function wrap(entry: C.IPage): C.Page;
export function wrap(entry: C.IPartnerChurch): C.PartnerChurch;
export function wrap(entry: C.IResource): C.Resource;
export function wrap(entry: C.IRedirect): C.Redirect;
export function wrap(entry: C.IResourceSet): C.ResourceSet;
export function wrap(entry: C.ISectionBlockText): C.SectionBlockText;
export function wrap(entry: C.IResourceTree): C.ResourceTree;
export function wrap(entry: C.ISectionCardDeck): C.SectionCardDeck;
export function wrap(entry: C.ISectionCard): C.SectionCard;
export function wrap(entry: C.ISectionDomainObjectHeader): C.SectionDomainObjectHeader;
export function wrap(entry: C.ISectionCodeWidget): C.SectionCodeWidget;
export function wrap(entry: C.ISectionContactUs): C.SectionContactUs;
export function wrap(entry: C.ISectionFaq): C.SectionFaq;
export function wrap(entry: C.ISectionEmailCapture): C.SectionEmailCapture;
export function wrap(entry: C.ISectionHero): C.SectionHero;
export function wrap(entry: C.ISectionFeaturedItems): C.SectionFeaturedItems;
export function wrap(entry: C.ISectionHttpError): C.SectionHttpError;
export function wrap(entry: C.ISectionImageGallery): C.SectionImageGallery;
export function wrap(entry: C.ISectionIntro): C.SectionIntro;
export function wrap(entry: C.ISectionMarqueeText): C.SectionMarqueeText;
export function wrap(entry: C.ISectionMinistryDetails): C.SectionMinistryDetails;
export function wrap(entry: C.ISectionLocationMap): C.SectionLocationMap;
export function wrap(entry: C.ISectionPartnerChurches): C.SectionPartnerChurches;
export function wrap(entry: C.ISectionProductList): C.SectionProductList;
export function wrap(entry: C.ISectionResourceLanding): C.SectionResourceLanding;
export function wrap(entry: C.ISectionVideo): C.SectionVideo;
export function wrap(entry: C.ISectionTestimonials): C.SectionTestimonials;
export function wrap(entry: C.ISiteConfig): C.SiteConfig;
export function wrap(entry: C.ISectionVideoHighlight): C.SectionVideoHighlight;
export function wrap(entry: C.ITestimonial): C.Testimonial;
export function wrap<CT extends keyof TypeDirectory>(entry: TypeDirectory[CT]): ClassDirectory[CT];
export function wrap(entry: IEntry<any>): IEntry<any> {
  const id = entry.sys.contentType.sys.id
  switch (id) {
    case 'cartButton':
      return new C.CartButton(entry)
    case 'card':
      return new C.Card(entry)
    case 'callToAction':
      return new C.CalltoAction(entry)
    case 'email':
      return new C.Email(entry)
    case 'conference':
      return new C.Conference(entry)
    case 'loginButton':
      return new C.LoginButton(entry)
    case 'faq':
      return new C.Faq(entry)
    case 'formField':
      return new C.FormField(entry)
    case 'menu':
      return new C.Menu(entry)
    case 'menuButton':
      return new C.MenuButton(entry)
    case 'migrationHistory':
      return new C.MigrationHistory(entry)
    case 'pageMetadata':
      return new C.PageMetadata(entry)
    case 'ministry':
      return new C.Ministry(entry)
    case 'page':
      return new C.Page(entry)
    case 'partnerChurch':
      return new C.PartnerChurch(entry)
    case 'resource':
      return new C.Resource(entry)
    case 'redirect':
      return new C.Redirect(entry)
    case 'resourceSet':
      return new C.ResourceSet(entry)
    case 'section-block-text':
      return new C.SectionBlockText(entry)
    case 'resourceTree':
      return new C.ResourceTree(entry)
    case 'section-card-deck':
      return new C.SectionCardDeck(entry)
    case 'section-card':
      return new C.SectionCard(entry)
    case 'section-domain-object-header':
      return new C.SectionDomainObjectHeader(entry)
    case 'section-code-widget':
      return new C.SectionCodeWidget(entry)
    case 'section-contact-us':
      return new C.SectionContactUs(entry)
    case 'section-faq':
      return new C.SectionFaq(entry)
    case 'section-email-capture':
      return new C.SectionEmailCapture(entry)
    case 'section-hero':
      return new C.SectionHero(entry)
    case 'section-featured-items':
      return new C.SectionFeaturedItems(entry)
    case 'section-http-error':
      return new C.SectionHttpError(entry)
    case 'section-image-gallery':
      return new C.SectionImageGallery(entry)
    case 'section-intro':
      return new C.SectionIntro(entry)
    case 'section-marquee-text':
      return new C.SectionMarqueeText(entry)
    case 'section-ministry-details':
      return new C.SectionMinistryDetails(entry)
    case 'section-location-map':
      return new C.SectionLocationMap(entry)
    case 'section-partner-churches':
      return new C.SectionPartnerChurches(entry)
    case 'section-product-list':
      return new C.SectionProductList(entry)
    case 'section-resource-landing':
      return new C.SectionResourceLanding(entry)
    case 'section-video':
      return new C.SectionVideo(entry)
    case 'section-testimonials':
      return new C.SectionTestimonials(entry)
    case 'site-config':
      return new C.SiteConfig(entry)
    case 'section-video-highlight':
      return new C.SectionVideoHighlight(entry)
    case 'testimonial':
      return new C.Testimonial(entry)
    default:
      throw new Error('Unknown content type:' + id)
  }
}
