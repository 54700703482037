import { wrap } from ".";
import { Entry, IEntry, ILink, isEntry } from "../base";
import { FormField, IFormField } from "./form_field";

export interface ISectionContactUsFields {
  internalTitle?: never;
  text: string;
  fields?: Array<ILink<'Entry'> | IFormField>;
  submitButtonText?: string;
  notificationEmail?: string;
  style?: SectionContactUsStyle;
  bookmarkTitle?: string;
  hubspotPortalId?: string;
  hubspotFormId?: string;
}

export type SectionContactUsStyle = 'default' | 'overlaps-previous';

/**
 * Section: Contact Us
 * A Contact Us section contains a Form with several Form Fields.  The responses to the form will be collected and provided to the appropriate administrator.
 */
export interface ISectionContactUs extends IEntry<ISectionContactUsFields> {
}

export function isSectionContactUs(entry: IEntry<any>): entry is ISectionContactUs {
  return entry &&
    entry.sys &&
    entry.sys.contentType &&
    entry.sys.contentType.sys &&
    entry.sys.contentType.sys.id == 'section-contact-us'
}

export class SectionContactUs extends Entry<ISectionContactUsFields> implements ISectionContactUs {
  get text(): string {
    return this.fields.text
  }

  get fields_get(): Array<FormField | null> | undefined {
    return !this.fields.fields ? undefined :
      this.fields.fields.map((item) =>
        isEntry(item) ? wrap<'formField'>(item) : null
      )
  }

  get submitButtonText(): string | undefined {
    return this.fields.submitButtonText
  }

  get submit_button_text(): string | undefined {
    return this.fields.submitButtonText
  }

  get notificationEmail(): string | undefined {
    return this.fields.notificationEmail
  }

  get notification_email(): string | undefined {
    return this.fields.notificationEmail
  }

  get style(): SectionContactUsStyle | undefined {
    return this.fields.style
  }

  get bookmarkTitle(): string | undefined {
    return this.fields.bookmarkTitle
  }

  get bookmark_title(): string | undefined {
    return this.fields.bookmarkTitle
  }

  get hubspotPortalId(): string | undefined {
    return this.fields.hubspotPortalId
  }

  get hubspot_portal_id(): string | undefined {
    return this.fields.hubspotPortalId
  }

  get hubspotFormId(): string | undefined {
    return this.fields.hubspotFormId
  }

  get hubspot_form_id(): string | undefined {
    return this.fields.hubspotFormId
  }

  constructor(entry: ISectionContactUs);
  constructor(id: string, fields: ISectionContactUsFields);
  constructor(entryOrId: ISectionContactUs | string, fields?: ISectionContactUsFields) {
    super(entryOrId, 'section-contact-us', fields)
  }
}
